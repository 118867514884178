<template>
  <tbody>
    <tr v-for="(item, index) in items" :key="item.id || index">
      <td>{{ item.order }}</td>
      <td>{{ item.companyKeysId || '-' }}</td>
      <td>{{ item.companyName || '-' }}</td>
      <td v-if="showAreaField">{{ item.area || '-' }}</td>
      <td>{{ item.sticker12 || '-' }}</td>
      <td>{{ item.sticker30 || '-' }}</td>
      <td>{{ item.createTimestamp | dateFilters }}</td>
      <td>{{ item.status | labelStickersStatus }}</td>
      <td class="text-center">
        <v-btn fab x-small :elevation="0" @click="handleClickEdit(item.id)">
          <v-icon dark>mdi-eye</v-icon>
        </v-btn>
      </td>
    </tr>
  </tbody>
</template>

<script>
// Constants
import { ROLES } from '@/constants'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Filters
import { dateFilters } from '@/filters/dateFilters'
import { labelStickersStatus } from '@/filters/app'
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'PanelUsersListItem',
  filters: {
    dateFilters,
    labelStickersStatus
  },
  mixins: [uiMixin],
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData']),
    /**
     * We must show the "area" field
     *
     * @return {boolean}
     */
    showAreaField() {
      return this.userData.role === ROLES.admin.value
    }
  },
  methods: {
    /**
     * Handle click on edit button
     */
    handleClickEdit(id) {
      if (id) {
        this.routerPushTo({
          path: `/stickers-orders-form/${id}`
        })
      }
    }
  }
}
</script>
