<template>
  <PanelSection
    title="Pedidos de etiquetas QR"
    :hide-action-buttons="false"
    primary-button-label="Nuevo pedido"
    @onClickAddButton="handleClickAddButton"
  >
    <div class="stickers-orders-list">
      <v-container>
        <CustomDataTable
          :data-table-v-options="dataTable.dataTableVOptions"
          :headers="dataTable.headers"
          :items-per-page="dataTable.itemsPerPage"
          :items="dataTable.itemsFiltered"
          :loading="dataTable.loading"
          :search-by="dataTable.searchBy"
          @onSearch="handleSearch"
        >
          <template slot-scope="{ items }">
            <StickersOrdersListItem :items="items" />
          </template>
        </CustomDataTable>
      </v-container>
    </div>
  </PanelSection>
</template>

<script>
// Constants
import { STICKERS_STATUS, ROLES } from '@/constants'
// Components
import PanelSection from '@/components/ui/PanelSection'
import CustomDataTable from '@/components/ui/CustomDataTable'
import StickersOrdersListItem from '@/components/elements/stickers/StickersOrdersListItem'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Services
import { getAllStickersOrders } from '@/services/stickersOrders'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { isNil, cloneDeep } from 'lodash'

export default {
  name: 'StickersOrdersList',
  components: { PanelSection, CustomDataTable, StickersOrdersListItem },
  mixins: [uiMixin],
  data() {
    return {
      // Data table
      dataTable: {
        dataTableVOptions: {
          'hide-default-footer': false,
          'show-custom-paginate': false
        },
        headers: [
          { text: 'Pedido', value: 'order' },
          { text: 'Código cliente', value: 'companyKeysId' },
          { text: 'Compañía', value: 'companyName' },
          { text: 'Pequeñas', value: 'sticker12' },
          { text: 'Grandes', value: 'sticker30' },
          { text: 'Fecha', value: 'createTimestamp' },
          { text: 'Estado', value: 'status' },
          { text: 'Acciones', align: 'center' }
        ],
        items: [],
        itemsFiltered: [],
        itemsPerPage: 15,
        loading: true,
        searchBy: [
          {
            id: 'order',
            format: 'number',
            label: 'Pedido',
            type: 'text',
            typeSearch: 'exact'
          },
          {
            id: 'companyKeysId',
            label: 'Código cliente',
            type: 'text',
            typeSearch: 'like'
          },
          {
            id: 'status',
            label: 'Estado',
            values: Object.values(STICKERS_STATUS),
            type: 'select',
            typeSearch: 'exact'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData']),
    /**
     * Get items filtered to table
     *
     * @return {array} - items to show in datatable
     */
    getItemsFiltered() {
      return this.dataTable.items
    }
  },
  async mounted() {
    this.setAttributesToDataTable()
    await this.setDataTableItems()
  },
  methods: {
    /**
     * Handle click on Add button
     */
    handleClickAddButton() {
      this.routerPushTo({
        path: '/stickers-orders-new'
      })
    },
    /**
     * Handle make a search
     *
     * @param {object} searchOptions - pagination options
     */
    handleSearch(searchOptions) {
      if (!isNil(searchOptions.query) && searchOptions.query !== '') {
        this.dataTable.itemsFiltered = this.dataTable.items.filter(item => {
          // Like
          if (searchOptions.typeSearch === 'like') {
            const patt = new RegExp(searchOptions.query, 'i')
            return patt.test(item[searchOptions.queryField])
          }
          // Exact
          return item[searchOptions.queryField] === searchOptions.query
        })
      } else {
        this.dataTable.itemsFiltered = cloneDeep(this.dataTable.items)
      }
    },
    /**
     * Set attribute to CustomDataTable (when we are admin)
     */
    setAttributesToDataTable() {
      if (this.userData.role === ROLES.admin.value) {
        this.dataTable.headers.splice(3, 0, {
          text: 'Área',
          value: 'area'
        })
        this.dataTable.searchBy.splice(2, 0, {
          id: 'area',
          label: 'Área',
          type: 'text',
          typeSearch: 'exact'
        })
      }
    },
    /**
     * Set items to data table
     *
     * @param {object} paginationOptions - pagination options
     */
    async setDataTableItems(paginationOptions = null) {
      const currentPaginationOptions = { ...paginationOptions, limit: null }

      this.dataTable.loading = true

      // Set filter to user role
      if (
        this.userData.role === ROLES.commercial.value &&
        this.userData.meta &&
        Array.isArray(this.userData.meta.areas)
      ) {
        const items = await Promise.all(
          this.userData.meta.areas.map(async area => {
            const itemsArea = await getAllStickersOrders({
              ...currentPaginationOptions,
              constraints: [['area', '==', area]]
            })
            return itemsArea
          })
        )
        this.dataTable.items = items.flat(1)
      } else if (this.userData.role === ROLES.provider.value) {
        const items = await Promise.all(
          [
            STICKERS_STATUS.accepted.value,
            STICKERS_STATUS.print.value,
            STICKERS_STATUS.sent.value
          ].map(async status => {
            const itemsArea = await getAllStickersOrders({
              ...currentPaginationOptions,
              constraints: [['status', '==', status]]
            })
            return itemsArea
          })
        )
        this.dataTable.items = items.flat(1)
      } else if (this.userData.role === ROLES.admin.value) {
        this.dataTable.items = await getAllStickersOrders(
          currentPaginationOptions
        )
      }

      // Modificamos el formato de los pedidos para poder representarlos en la tabla
      this.dataTable.items = this.dataTable.items.map(item => {
        item.sticker12 = item.stickers.sticker12
        item.sticker30 = item.stickers.sticker30
        return item
      })
      this.dataTable.loading = false
      // Set itemsFiltered
      this.dataTable.itemsFiltered = cloneDeep(this.dataTable.items)
    }
  }
}
</script>
